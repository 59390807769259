import React, { useContext } from 'react'
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  Flex,
  Box,
  SimpleGrid,
} from '@chakra-ui/react'
import Img from "gatsby-image/withIEPolyfill"
import { H3, H4, H6 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
import { colors } from '../theme/themeAlopex'

const TalentGrid = ({ filter }) => {
  const { color } = useContext(ColorContext)
  const metaFilter = filter ? filter : ['accounts', 'creative', 'development']
  const data = useStaticQuery(graphql`
  query TalentGridQuery {
    allTalentJson(sort: {fields: order, order: ASC}) {
      nodes {
        fName
        lName
        position
        roles
        photo
        order
        fields {
          imageRel {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`)

 // Match roles with colors
 const switchRoles = (role) => {
  switch (role) {
    case 'accounts': 
      return colors.brand.slate
    case 'creative':
      return color.alpha
    case 'development':
      return color.bravo
    default: 
    return colors.brand.slate
  }
}

// Spit back a linear grad or hex bg based on roles.length
const genBG = (roles) => {
  if (roles.length > 1) {
    let bgs = roles.map((r, i) => {
      return switchRoles(r)
    })
    return `linear-gradient(135deg, ${bgs.join(', ')})`
  } else {
    return switchRoles(roles[0])
  }
}

const hasMatch = (arr1, arr2) => {
  let ret = []
  for(let i in arr1) {   
      if(arr2.indexOf(arr1[i]) > -1){
          ret.push(arr1[i])
      }
  }
  return ret
}

  return (
    <SimpleGrid columns={[1, null, 2, 3]} backgroundColor={colors.brand.slate} borderRight={`1px solid ${colors.brand.lightGray}`} borderBottom={`1px solid ${colors.brand.lightGray}`}>
    {data.allTalentJson.nodes.map((tal, i) => {
      return (
        <>
        {hasMatch(tal.roles, metaFilter).length > 0 &&
          <Box key={`talent-${i}`} backgroundColor={colors.brand.slate}>
            <Link to={`/talent/${(tal.fName.replace(/ /g, '') + ' ' + tal.lName.replace(/ /g, '')).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')}`}>
              <Box
                height={0}
                paddingTop="56.25%"
                position="relative"
                transition="background 0.3s ease-in-out"
                cursor="pointer"
                overflow="hidden"
                borderTop={`1px solid ${colors.brand.lightGray}`}
                borderLeft={`1px solid ${colors.brand.lightGray}`}
                _before={{
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0, 
                  left: 0,
                  background: genBG(tal.roles),
                  transition: 'opacity 0.6s ease-in-out',
                  opacity: 1,
                }}
                _hover={{
                  '&::before': {
                    opacity: 0
                  }
                }}
                sx={{
                  '.gatsby-image-wrapper': {
                    transition: 'transform 0.6s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)'
                    }
                  }
                }}
              >
                <Img
                  key={`talent-${i}`}
                  objectFit="cover"
                  objectPosition="100%"
                  style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
                  fluid={tal.fields.imageRel.childImageSharp.fluid}
                />
                <Box position="absolute" left="20px" bottom="20px" color={colors.brand.white} textTransform="uppercase">
                  <H4 mb="0" lineHeight="1">{tal.fName}</H4>
                  <H4 mb="0" lineHeight="1">{tal.lName}</H4>
                  <H6 mb="0">{tal.position}</H6>
                </Box>
              </Box>
            </Link>
          </Box>
        }
        </>
      )
    })}
    <Box height={0} paddingTop="56.25%" position="relative" background={colors.brand.slate} textAlign="center" color={colors.brand.white} borderTop={`1px solid ${colors.brand.lightGray}`} borderLeft={`1px solid ${colors.brand.lightGray}`}>
      <Link to="/application">
        <Flex position="absolute" width="100%" height="100%" top="0" left="0" justifyContent="center" alignItems="center" flexDirection="column">
          <H3>Want to join our team?</H3>
          <H4>Apply Here</H4>
        </Flex>
      </Link>
    </Box>
  </SimpleGrid>
  )
}

export default TalentGrid